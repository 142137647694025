//GLOBAL VARIABLES
//  var appUrl = "https://dev.usdcf.deloitte.com/";
//  var apiUrl = "https://dapi.usdcf.deloitte.com/api/";
//  var apiDocManagerUrl = "https://dapi.usdcf.deloitte.com/api/";

// var appUrl = "http://localhost:4200/";
// var apiUrl = "https://localhost:7053/api/";
// var apiRoleUrl = "https://localhost:7091/api/";
// var apiDocManagerUrl = "https://localhost:7181/api/";

$("#createForm").css({ visibility: "hidden"})
$("#updateForm").css({ visibility: "hidden"})

var userGroups;
localStorage.setItem("FormType","CrossBorder");
localStorage.removeItem("UploadFileInProgress");
var invalidCharactersList = ["#", "{", "}", "%", "~", "&"]
var attachmentsMaxSize = 104857600;
var maxAllowedFileLength = 128;
var documentReviewProcessReviewersCount = 3;
var currentUser;
var securityContext;
var reviewProcessContext;
var documentObj;
var appContext;
var accessToken;
var AdGroupId;
var CreatedDate;
var notS24GroupItem;
var myMSALObj;
var series24List;
var adUserGroup;
var UserGroup;
var errorMessage = '<h1>There has been an error. Please try again</h1>';
var invalidMessage = '<h1>Session Expired. Form will be reloaded.</h1>';
var submitFormMessage = '<h1>The registration process has been successfully completed.</h1>';
var FromLevel="New";
var isXBAdmin = false;
var displayNewquestions = true;
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;

let signInType;
let accountId = "";

var attachEnviroment = 'CrossBorderAttachments'
var attachRedLineEnviroment = 'CrossBorderRedLineAttachments'
var enviroment = 'CrossBorderForm'
var attachRecommendedSectionEnviroment = 'CrossBorderRecommendedTemplates'
var crossBorderTemplates = 'CrossBorderTemplates'
var validationEnviroment = 'ValidationFieldsCrossBorder'
var industryList = 'CrossBorderIndustries'
var approvalReviewStatusList = 'CrossBorderApprovalReviewStatus'
var reviewStatusList = 'CrossBorderReviewStatus'
var documentTypesList = 'CrossBorderDocumentTypes'
var reviewCommentsList = 'CrossBorderComments'
var additionalDocumentTypesList = 'CrossBorderAdditionalDocumentTypes'
var countryList = 'CrossBorderCountries'
var configurationApp = 'CrossBorderAppConfiguration'

var businessView = 'BusinessReview.aspx'
var qrmView = 'QRMReview.aspx'
var complianceView = 'ComplianceReview.aspx'
var businessViewButtonText = 'Pending for Business Review List'
var qrmViewButtonText = 'Pending for QRM Review List'
var complianceViewButtonText = 'Pending for Compliance Review List'

var gblFormId = 0;
var xbProjectName = "";
var xbFormId = "";
var previpus = "";
var gblItemsLeftToBeSubmitted = 0;
var gblFormStatus = '';
var gblFormGroup = 'MemberFirmTeam';
var gblSchedule3DocumentRequired=true;
var hasSchedule3Attachment = false;
var autosavedExecuted = false;
var CompanySubsectorComments,
ShareholderObjectivesComments,
GeographConcentrationComments,
ClientAffiliateComments,
ExpectedCompanyValueComments,
CreatedBy,
USCounterpartiesContactComments;
const documentSchedule3Object = { documentTypeId: 3, documentTypeTitle: 'Schedule 3: US Potential Counterparties' };
var environmentVariables= getEnvironmentVariable(localStorage.getItem("EnvironmentFlag"));
appContext = environmentVariables[0];
     var appUrl = appContext.redirectSite;
     var apiUrl = appContext.apiUrl;
     var apiDocManagerUrl;
     if(localStorage.getItem("EnvironmentFlag")=="local"){
        apiDocManagerUrl=appContext.apiDocManagerUrl;
     }
     else{
        apiDocManagerUrl=appContext.apiUrl;
     }

     
    var isPeoplePickerLoad=true;
    var  pathName="";
    if(window.location.pathname=="/")
    pathName="engagementDashboard";
    else
    pathName=window.location.pathname.substring(1);
//    $("#onetrust-consent-sdk").css("display", "none");

//if(localStorage.getItem('CurrentAccessPage')!="TerminationForm"){
    $("#test").css({ position: "absolute"});
    hideSpinner();    
//}
//localStorage.setItem('form_id',"0")

        if (GetURLParameter('item_id') == null && localStorage.getItem('isAuthorized')=="true"){
            
            $("#createForm").css({ visibility: "visible"});
            $("#updateForm").css({ visibility: "hidden",height:0,display:"none"});
             
            if(localStorage.getItem("userProfileName")!=null && localStorage.getItem("userProfileName")!=undefined)
            {
            var analyticsDataLayer =
            {
           'pageInfo': 
           {
           'pageName':pathName,
           'userID': localStorage.getItem("userProfileName"), //User Alias, no domain,
           'applicationName':"Advisory DCF Forms Modernization" // application name
           }
           };
           setTimeout(function(){
            window._satellite.track("pageView",analyticsDataLayer);
        }, 2000);
            //window._satellite.track("pageView",analyticsDataLayer);
            // $("#onetrust-consent-sdk").css("display", "block");
       
        }
       // $("#onetrust-consent-sdk").css("display", "block");
        }
        else if(localStorage.getItem('isAuthorized')=="true" && GetURLParameter('item_id') != null ) {
            localStorage.setItem('form_id',GetURLParameter('item_id'))
            localStorage.getItem('CurrentAccessPage')!="EHO"?showSpinner():"";
            $("#test").css({ position: "fixed"})
            $("#createForm").css({ visibility: "hidden",height:0,display:"none"});
            $("#updateForm").css({ visibility: "visible"});
            FromLevel="Update";
              myMSALObj = new msal.PublicClientApplication(GenerateMsalConfig(appContext.appClientId,appContext.redirectSite));

                const ActiveAccount = myMSALObj.getAllAccounts();

                if (ActiveAccount.length >= 1) {
                    accountId = ActiveAccount[0].homeAccountId;
                    getAdGroupData();
                }
                if(localStorage.getItem("userProfileName")!=null && localStorage.getItem("userProfileName")!=undefined)
            {
                var analyticsDataLayer =
                {
               'pageInfo': 
               {
               'pageName':pathName,
               'userID': localStorage.getItem("userProfileName"), //User Alias, no domain,
               'applicationName':"Advisory DCF Forms Modernization" // application name
               }
               };
               setTimeout(function(){
                window._satellite.track("pageView",analyticsDataLayer);
            }, 2000);
               
            }
        }
        else
        {
            $("#createForm").css({ visibility: "hidden"})
            $("#updateForm").css({ visibility: "hidden"})
        }        
   registeredScrollTopEvent();
function redirectCustomList() {
  $('#submit-modal').on('click', '.redirect', redirectOkModal);
    //  $("#onetrust-consent-sdk").css("display", "block");
}

$('#confirm-modal-button').on('click', function (e) {
    e.stopPropagation();
})

$('#submit-modal-button').on('click', function (e) {
    e.stopPropagation();

})
$('#review-assistant-modal-button').on('click', function (e) {
    e.stopPropagation();
})

$('#templates-modal-button').on('click', function (e) {
    e.stopPropagation();
})
$('.close-modal-span').click(function(e){
    $('body').css('overflow','auto');
})
$('#docTemplateCloseId').click(function(e){
    $('body').css('overflow','auto');
})

function showModalRedirectOrSave(formStatus) {
    $(".modal-body")[0].innerHTML = getConfirmationMessage();
    clickSubmitModalButton();
    if (formStatus != 'Draft' && formStatus != 'Reviewed') {
        redirectCustomList();
    }
}

function showError(Data) {

    if(Data!=undefined && Data.status==403)
    {
        if(JSON.parse(Data.responseText).stringKey=="tokenexpired")
        {
            showModalMessage(invalidMessage);
        }
        else
        {
            showModalMessage(errorMessage)
        }
    }
    else
    {
        showModalMessage(errorMessage);
    }

}

function clickSubmitModalButton() {
  //  $("#submit-modal-button").click();
  
    $('#submit-modal').modal('show')
    moveToBackModal($("#review-assistant-modal"));
    moveToBackModal($("#templates-modal"));
}
function registeredScrollTopEvent() {
    $(".redirect").on('click', function () {
        var body = $(".modal-body")[0].innerHTML;
        $('body').css('overflow','auto');//To enable ScrollBar
        if (body == requiredFieldsMessage || body == formLoadOkMessage || body == invalidFieldsMessage || body == textFieldLongerThan255) {
           if(body == requiredFieldsMessage){
            $("#s4-workspace").scrollTop(0);
            $("#s4-workspace").get(0).scrollIntoView({behavior: 'smooth'});
           }
        } else if (body == updatedFormMessage) {
            location.reload(true);
        } else {
            if(body==saveMessage){
                $("#test").scrollTop(0);
                $("#test").get(0).scrollIntoView({behavior: 'smooth'});
                // $("#saveButton").scrollBottom(0);
                //$("#saveButton").get(0).scrollIntoView({behavior: 'smooth'}); 
                //window.scrollBy(0, 300);
                 }
            if (body == discontinueReasonNeeded) {
                moveToFrontModal($("#discontinue-with-reason-modal"));
                onModalDiscontinue();
            }
            if (body == putOnHoldReasonNeeded) {
                moveToFrontModal($("#put-on-hold-with-reason-modal"));
                onModalPutOnHold();
            }
        }
        moveToFrontModal($("#review-assistant-modal"));
        moveToFrontModal($("#templates-modal"));
    })
}

function addPPRequiredClass(peoplePickerDiv) {
    peoplePickerDiv.addClass("required");
}

function removePPRequiredClass(peoplePickerDiv) {
    peoplePickerDiv.removeClass("required");
}

//set the form status
function setFormStatus(button) {
    if (typeof (gblFormStatus) != 'undefined') {
        gblFormStatus = button.attr('data-submitType')
    }
}

function doSave() {
    showSpinner();
    var deferred = $.Deferred();
    var itemId = gblFormId;
    if (isValidAction('save')) {
        if (itemId == 0) {
            gblFormStatus = 'Draft';
            $.when(apiPost()).then(function (data) {
                gblFormId = data.d.ID
                hideSpinner();
                deferred.resolve();
            }).fail(function (error) {
                hideSpinner();
                deferred.reject(error);
            });
        } else {
            autosavedExecuted = true;
            $.when(apiPut(gblFormId)).then(function () {
                autosavedExecuted = false;
                hideSpinner();
                deferred.resolve();
            }).fail(function (error) {
                autosavedExecuted = false;
                hideSpinner();
                deferred.reject(error);
            });
        }
    } else {
        hideSpinner();
        deferred.reject();
    }

    return deferred.promise();
}
function getCreateFormMissingRequiredFields() {
    var invalidFields = []
    var pUsers = [];
    var arr = []
    $('#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        arr.push({ 'Email': $(item).attr('email') })
    })
    if(arr.length==0)
    invalidFields.push("peoplePickerCreateDivMemberFirmTeam");

    return invalidFields;
}
function createHighlightMissingRequiredFields() {
    cleanMissingRequiredFields()
    var missingFields = getCreateFormMissingRequiredFields();
    for (var field in missingFields) {
        var input = getInputName(field,missingFields);
        var parentItem = input.parents(".required-div");
        parentItem.find(".span-to-highlight").addClass("bg-missingRequiredField");
    }
}
$('body').on('click', '.forminitiate', function (e) {
	$(".forminitiate").attr("disabled",true);
	e.preventDefault();
   if (getCreateFormMissingRequiredFields().length == 0) {
        createForm();
    }
    else {
       	createHighlightMissingRequiredFields();
		$(".forminitiate").attr("disabled",false);
	}
})
$('body').on("click", ".redirect", function () {
    // $("#onetrust-consent-sdk").css("display", "block");
	$(".forminitiate").attr("disabled",false);

if($(".modal-body")[0].innerHTML==invalidMessage)
{
    localStorage.clear();
    window.location.href = appUrl+"crossborderform?item_id="+xbFormId;
}
else{

    if(gblFormStatus=="")
    window.location.href = appUrl+"crossborderform?item_id="+xbFormId;
    if(FromLevel!="Update")
    {
    $("#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-drop .chosen-results").children().remove();
    $("#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-choices .search-choice").remove();
    }
    if(gblFormStatus=="Reviewed")
    {
        window.location.href = appUrl+"crossborderform?item_id="+xbFormId;
    }
}
    
    
})
function removeDuplicates(arr) {
    let uniqueArr = [...new Set(arr)];
    return uniqueArr;
 }
function createForm() {
    var pUsers = [];
    var arr = []
    var pUsersName = [];
    var arrName = []
    $('#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        arr.push($(item).attr('email') )
        arrName.push(item.innerText);

    })
   let unique= removeDuplicates(arr) 
   let uniqueName= removeDuplicates(arrName) 
   
    pUsers["InternationalMemberFirmTeam"] = unique;
    pUsersName["InternationalMemberFirmTeamDisplayNames"] = uniqueName;
    let CreatedBy=localStorage.getItem("userProfileName");
    
    var jsonItem = {'FormStatus': 'Draft','FormActionGroup':'MemberFirmTeam', 'InternationalMemberFirmTeam': pUsers["InternationalMemberFirmTeam"],'InternationalMemberFirmTeamDisplayNames': pUsersName["InternationalMemberFirmTeamDisplayNames"],'CreatedBy':CreatedBy};

                    $.ajax({
                        url:apiUrl+"CrossBorderForm/Add",
                        type: "POST",
                        headers: {
                            "content-Type": "application/json",
                            "Authorization" : getTokenValue(),
                            "FormType" : getFormType(),
                            "UserRoles": getUserRoles()
                        },
                        data: JSON.stringify(jsonItem),
                        success: function(data) {
                            gblFormId=0;
                            xbFormId = data;
                            cleanMissingRequiredFields();
                            $(".modal-body-create")[0].innerHTML = submitFormMessage;
                            
                            $('#submit-modal-create').modal('show')
                            
                        },
                        error: function(data) {
                            showError(data);
                        }
                    });
            
 
}

//get the request digest and submit the form after
$('body').on('click', '.formsub', function (e) {
    e.preventDefault();
    stopAutoSave();
    var button = $(this);
    if (isValidAction('submit')) {
        $('body').off('click', '.formsub');
        $('body').on('click', '.formsub', function (e) {
            e.preventDefault();
        })
        setFormStatus(button);
        if (gblFormId == 0) {
            $.when(apiPost()).then(function (data) {
                gblFormId = data.d.ID
                showModalRedirectOrSave(gblFormStatus);
            });
        }
        else {
            showSpinner();
            $.when(apiPut(gblFormId)).then(function () {
                hideSpinner();
                showModalRedirectOrSave(gblFormStatus);
            });
        }
    }
})

//Approve action
$('body').on('click', '.approve', function (e) {
    e.preventDefault();
    stopAutoSave();
    var button = $(this)
    var sectionApproved = e.target.getAttribute('data-actiongroup');
    var dateApproval;
    var signatureApproval;
    switch (sectionApproved) {
        case "IndustryRepresentative":
            dateApproval = "DCFDateApproval";
            signatureApproval = "IndustryRepresentativeSignature";
            //addPPRequiredClass($("[id=peoplePickerDivDCFEngagementTeam"));
            //addPPRequiredClass($("[id=peoplePickerDivDealTeamBusinessReviewer"));
            addPPRequiredClass($("[id=DCFEngagementTeam"));
            addPPRequiredClass($("[id=DealTeamBusinessReviewer"));
            addPPRequiredClass($("[id=Series24"));
            break;
        case "Series24":
            dateApproval = "Series24DateApproval";
            signatureApproval = "Series24Signature";
            break;
    }
    if (isValidAction('approve')) {
        setFormStatus(button);
        var currentDate = new Date();
        var approvalInput = $('[name=' + dateApproval + ']');
        approvalInput.val(currentDate)
        approvalInput.attr("approvalDate", currentDate);
        $('[name=' + signatureApproval + ']').val(currentUser.userId)
        showSpinner();
if(signatureApproval=="Series24Signature")
{
if(userGroups.find(x=>x.Role=="DCF_CrossBorder_S24" && x.Email.trim().toLowerCase()==currentUser.email.trim().toLowerCase()))
{
    $.when(apiPut(gblFormId)).then(function () {
        hideSpinner();
        showModalRedirectOrSave(gblFormStatus);
    });
}
hideSpinner();
}
else
{
    $.when(apiPut(gblFormId)).then(function () {
        hideSpinner();
        showModalRedirectOrSave(gblFormStatus);
    });
}
     
    }
})

function setRequiredToPeoplePickerWhenReturned(e) {
    var sectionApproved = e.target.getAttribute('data-actiongroup');
    switch (sectionApproved) {
        case "IndustryRepresentative":
            //removePPRequiredClass($("[id=peoplePickerDivDCFEngagementTeam"));
            removePPRequiredClass($("[id=Series24"));
            //removePPRequiredClass($("[id=peoplePickerDivDealTeamBusinessReviewer"));
            removePPRequiredClass($("[id=DCFEngagementTeam"));
            removePPRequiredClass($("[id=DealTeamBusinessReviewer"));
            break;
        case "Series24":
            addPPRequiredClass($("[id=DCFEngagementTeam"));
            addPPRequiredClass($("[id=Series24"));
            addPPRequiredClass($("[id=DealTeamBusinessReviewer"));
            //addPPRequiredClass($("[id=peoplePickerDivDCFEngagementTeam"));
            //addPPRequiredClass($("[id=peoplePickerDivDealTeamBusinessReviewer"));
            break;
    }
}

//Return for rework action
$('body').on('click', '.reject', function (e) {
    e.preventDefault();
    var button = $(this);
    stopAutoSave();
    setRequiredToPeoplePickerWhenReturned(e);
    if (isValidAction('reject')) {
        var actionGroup = button.attr("data-actiongroup");
        var rfrComments = actionGroup + "Comments";
        if ($("[name='" + rfrComments + "']").val().trim()) {
            $('body').off('click', '.reject');
            $('body').on('click', '.reject', function (e) {
                e.preventDefault();
            })
            setFormStatus(button);
            var id = gblFormId;
            if (id == 0) {
                $.when(apiPost()).then(function (data) {
                    gblFormId = data.ID
                    showModalRedirectOrSave(gblFormStatus);
                });
            } else {
                if (actionGroup == "Series24") {
                    $.when(series24ReturnForReworkAction()).done(function () {
                        $.when(apiPut(id)).then(function () {
                            showModalRedirectOrSave(gblFormStatus);
                        });
                    });
                } else {
                    $.when(apiPut(id)).then(function () {
                        showModalRedirectOrSave(gblFormStatus);
                    });
                }
            }
        } else {
            showModalMessage(missingCommentsRework);
            highlightMissingRequiredFields();
            reActivateAutoSave();
        }
    }
})

$('body').on('click', '.discontinue-button', discontinueForm);

function discontinueForm(e) {
    e.preventDefault();
    stopAutoSave();
    var discontinueButton = $(this);
    cleanMissingRequiredFields();
    if (isValidAction('discontinue')) {
        $('body').off('click', '.discontinue-button');
        $('body').on('click', '.discontinue-button', discontinueForm);

        var discontinueHelper = new RequiredFieldsForDiscontinue(securityContext);
        if (discontinueHelper.hasMissingRequiredFields()) {
            showModalMessage(requiredFieldsMessage);
            discontinueHelper.highlightMissingRequiredFields();
            reActivateAutoSave();
        } else {
            setMessageDiscontinueFormAction();
            var funcOK = function () {
               
                offModalDiscontinue();
                moveToBackModal($("#discontinue-with-reason-modal"));
                var textArea = $("#DiscontinueReason");
                if (textArea.val().trim()) {
                    setFormStatus(discontinueButton);
                    setDiscontinueFields();
                    showSpinner();
                    $.when(apiPut(gblFormId)).then(function () {
                        hideSpinner();
                        showModalRedirectOrSave(gblFormStatus);
                    });
                } else {
                    showModalMessage(discontinueReasonNeeded);
                    stopAutoSave();
                    reActivateAutoSave();
                }
            }
            var funcCancel = function () {
                stopAutoSave();
                reActivateAutoSave();
            }
            confirmModalWithReasonAction('discontinue', funcOK, funcCancel);
        }
    }
}

function offModalDiscontinue() {
    $("#discontinue-with-reason-modal .ok-cancel-button-return-modal").removeClass("confirmation-modal-button");
}

function onModalDiscontinue() {
    $("#discontinue-with-reason-modal .ok-cancel-button-return-modal").addClass("confirmation-modal-button");
}

function offModalPutOnHold() {
    $("#put-on-hold-with-reason-modal .ok-cancel-button-return-modal").removeClass("confirmation-modal-button");
}

function onModalPutOnHold() {
    $("#put-on-hold-with-reason-modal .ok-cancel-button-return-modal").addClass("confirmation-modal-button");
}

// Put form on Hold
$('body').on('click', '.on-hold-button', putOnHoldForm);

function putOnHoldForm(e) {
    e.preventDefault();
    stopAutoSave();
    var putOnHoldButton = $(this);
    cleanMissingRequiredFields();
    if (isValidAction('putOnHold')) {
        $('body').off('click', '.on-hold-button');
        $('body').on('click', '.on-hold-button', putOnHoldForm);

        setMessagePutOnHoldFormAction();
        var funcOK = function () {
            offModalPutOnHold();
            moveToBackModal($("#put-on-hold-with-reason-modal"));
            var textArea = $("#PutOnHoldReason");
            if (textArea.val().trim()) {
                setFormStatus(putOnHoldButton);
                setPutOnHoldFields();
                showSpinner();
                $.when(apiPut(gblFormId)).then(function () {
                    hideSpinner();
                    showModalRedirectOrSave(gblFormStatus);
                });
            } else {
                showModalMessage(putOnHoldReasonNeeded);
                stopAutoSave();
                reActivateAutoSave();
            }
        }
        var funcCancel = function () {
            stopAutoSave();
            reActivateAutoSave();
        }
        confirmModalWithReasonAction('putOnHold', funcOK, funcCancel);
    }
}

// Reactivate form
$('body').on('click', '.reactivate-button', reactivateForm);

function reactivateForm(e) {
    e.preventDefault();
    var reactivateButton = $(this);
    $('body').off('click', '.reactivate-button');
    $('body').on('click', '.reactivate-button', reactivateForm);

    showModalReactivateForm();
    var funcReactivate = function () {
        showSpinner();
        var previousStatus = gblFormStatus;
        setFormStatus(reactivateButton);
        reactivateFormPost(previousStatus);
    }
    confirmModalAction(funcReactivate);
}

//fill form
function fillForm(data, countryList, adUserGroup, industryList,s24List,requiredFieldList) {
    for (var key in data) {
        var item = $('[name=' + key + ']');
        if (item[0] != undefined) {
            //if there is more than 1 item the items are radios or checkboxes
            if (item.length > 1) {
                if (item.is(':radio')) {
                    var radio = $('[name=' + key + '][value=\'' + data[key] + '\']');
                    radio.prop("checked", true);
                }
                if (item.is(':checkbox') && data[key]) {
                    for (var i in data[key]) {
                        var checkbox = $('[name=' + key + '][value=' + data[key][i] + ']');
                        checkbox.prop("checked", true);
                    }
                }
            }
            else if (item.hasClass('input-data-text')) {
                item.val(data[key]);

                
                if (key === "DFLOW") {
                    processAndStoreUrls(data[key]);
                }

                if (data[key] == "PutOnHoldReason") {
                    $("#PutOnHoldReason").val(data[key])
                }
            }
            else if (item.hasClass('input-data-float')) {
                item.val(data[key]);
                item.change();
                item.blur();
            }
            else if (item.hasClass('input-data-integer')) {
                item.val(data[key]);
                item.change();
                item.blur();
            }
            else if (item.hasClass('input-data-currency')) {
                item.val(data[key]);
                item.change();
                item.blur();
            }
            else if (item.hasClass('input-data-select')) {
                if (item.attr("Id") == "Industry") {
                    populateIndustry(industryList, data[key]);
                }
                if (item.attr("Id") == "Country") {
                    populateCountry(countryList, data[key]);
                }

            }
            else if (item.hasClass('chosen-select-no-results')) {
                if (item.attr("Id") == "DCFEngagementTeam") {
                    populateEngagementTeam(adUserGroup, data[key], item.attr("Id"));
                }
                if (item.attr("Id") == "DealTeamBusinessReviewer") {
                    populateEngagementTeam(adUserGroup, data[key], item.attr("Id"));

                }
                if (item.attr("Id") == "Series24") {
                    populateEngagementTeam(s24List, data[key], item.attr("Id"));

                }

            }
            else if (item.hasClass('input-data-date')) {
                if (data[key] != null) {
                    if (item.hasClass("signature-date")) {
                        item.val(data[key]);
                        item.attr("approvalDate", data[key]);
                    } else {
                        item.val(formatDate(data[key]));
                    }
                }
            }
        }
       
    }
    addClassToRequiredFields(requiredFieldList);
    //populateRequiredFields();
    initializeDisabledInputs();
}


function setJsonItem() {
    var dfd = jQuery.Deferred();

    var jsonItem = {}
    if (gblFormStatus != 'Draft' && !autosavedExecuted) {
        jsonItem['FormStatus'] = gblFormStatus;
       
    }
    if(gblFormId!=""&&gblFormId!=undefined)
    {
        jsonItem['ID'] = gblFormId;
        jsonItem['Created'] = CreatedDate;
        jsonItem['FormActionGroup'] = gblFormGroup;
        jsonItem['FormStatus'] = gblFormStatus;
        
    }
    if(gblFormGroup=="MemberFirmTeam" && gblFormStatus=="Submitted")
    {
        jsonItem['FormActionGroup'] = "IndustryRepresentative";
        jsonItem['FormStatus'] = "Pending";  
    }
    if(gblFormGroup=="IndustryRepresentative" && gblFormStatus=="Rejected")
    {
        jsonItem['FormActionGroup'] = "MemberFirmTeam";
        jsonItem['FormStatus'] = "Pending";  
    }
    if(gblFormGroup=="IndustryRepresentative" && gblFormStatus=="Approved")
    {
        jsonItem['FormActionGroup'] = "SecondSubmissionTeam";
        jsonItem['FormStatus'] = "Pending";  
    }
    if(gblFormGroup=="SecondSubmissionTeam" && gblFormStatus=="Submitted")
    {
        jsonItem['FormActionGroup'] = "BusinessQRMCompliance";
        jsonItem['FormStatus'] = "Pending";  
    }
    if(gblFormGroup=="BusinessQRMCompliance" && gblFormStatus=="Submitted")
    {
        jsonItem['FormActionGroup'] = "Series24";
        jsonItem['FormStatus'] = "Pending";  
    }
    if(gblFormGroup=="BusinessQRMCompliance" && gblFormStatus=="Reviewed")
    {
        jsonItem['FormActionGroup'] = "BusinessQRMCompliance";
        jsonItem['FormStatus'] = "Pending";  
    }
    if(gblFormGroup=="Series24" && gblFormStatus=="Approved")
    {
        jsonItem['FormActionGroup'] = "Series24";
        jsonItem['FormStatus'] = "Completed";  
    }
    if(gblFormGroup=="Series24" && gblFormStatus=="Rejected")
    {
        jsonItem['FormActionGroup'] = "BusinessQRMCompliance";
        jsonItem['FormStatus'] = "Pending";
        jsonItem['series24ReturnedForRework'] = "YES";
    }
    if(gblFormGroup=="Series24")
    {
        jsonItem['CompanySubsectorComments'] = CompanySubsectorComments;
        jsonItem['ShareholderObjectivesComments'] = ShareholderObjectivesComments;
        jsonItem['GeographConcentrationComments'] = GeographConcentrationComments;
        jsonItem['ClientAffiliateComments'] = ClientAffiliateComments;        
        jsonItem['USCounterpartiesContactComments'] =  USCounterpartiesContactComments;
        jsonItem['ExpectedCompanyValueComments'] =  ExpectedCompanyValueComments;
    }
    jsonItem['CreatedBy']=CreatedBy;
    jsonItem['ModifiedBy']=localStorage.getItem("userProfileName");
    
    $.each($(".input-data-date"), function () {
        var inputdata = $(this);
        var inputVal = inputdata.val();
        if (inputVal != '') {
            if(typeof(inputdata.attr("approvalDate")) == "string" && inputdata.attr("approvalDate").includes("/"))
            {
                dateToSent = inputdata.attr("approvalDate");
            }
            else
            {
                dateToSent = new Date(inputdata.attr("approvalDate"));
            }
        }
        else {
            dateToSent = null;

        }
        jsonItem[inputdata.attr('name')] = dateToSent;
    })

    $.each($(".input-data-text"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val();
    })

    $.each($(".input-data-float"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val()
    })

    $.each($(".input-data-integer"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val()
    })

    $.each($(".input-data-currency"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val();
       // jsonItem[inputdata.attr('name')] = formatNumberToSend(inputdata.val(), false);
    })

    //go over all checkboxes section
    $.each($(".checkbox-section"), function () {
        var CheckedResults = [];
        var checkBoxSection = $(this);

        $.each(checkBoxSection.find($(".input-data-checkbox")), function () {
            var inputdata = $(this);
            if (inputdata.is(':checked')) {
                CheckedResults.push(inputdata.val());
            }
        })
        jsonItem[checkBoxSection.attr('id')] = CheckedResults;
    })

    //go over all radios
    $.each($(".input-data-option"), function () {
        var inputdata = $(this);
        if (inputdata.is(':checked')) {
            jsonItem[inputdata.attr('name')] = inputdata.val();
        }
        else {
            if (!jsonItem[inputdata.attr('name')]) {
                jsonItem[inputdata.attr('name')] = null;
            }
        }
    })

    //go over all selects
    $.each($(".input-data-select"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val();
    })

    var pUsers = [];
    var arr = []
    var DCFEngagementTeamDisplayNames=[];
    $('#DCFEngagementTeam_chosen .chosen-choices .search-choice').each(function (i, item) {
        arr.push( $(item).attr('email'))
        DCFEngagementTeamDisplayNames.push(item.innerText)
    })
    pUsers["DCFEngagementTeam"] = arr;
    

    // $.when(retrievePeoplePickerIds(pUsers)).then(function (ppIds) {
    // jsonItem = addPeoplePickerIdsToObject(ppIds, jsonItem)
    // dfd.resolve(jsonItem);
    // })

    var bUsers = [];
    var l = [];
    var DealTeamBusinessReviewerName=[];
    $('#DealTeamBusinessReviewer_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        l.push($(item).attr('email'));
        DealTeamBusinessReviewerName.push(item.innerText);
    })
    bUsers["DealTeamBusinessReviewer"] = l;
    var m = []
    var name = []
    $('#peoplePickerDivMemberFirmTeam_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        m.push($(item).attr('email'))
        name.push(item.innerText);
        
    })
    let uniqueArr= removeDuplicates(m);
    let uniqueNameArr= removeDuplicates(name) 
    jsonItem["InternationalMemberFirmTeam"]=uniqueArr;
    jsonItem["InternationalMemberFirmTeamDisplayNames"]=uniqueNameArr;


    jsonItem["DealTeamBusinessReviewer"]=l
    jsonItem["DealTeamBusinessReviewerDisplayNames"]=DealTeamBusinessReviewerName;
    jsonItem["DCFEngagementTeam"]=arr;
    jsonItem["DCFEngagementTeamDisplayNames"]=DCFEngagementTeamDisplayNames;
    var sUsers = [];
    var sl = [];
    var sName = []
    $('#Series24_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        sl.push($(item).attr('email'));
        sName.push(item.innerText);
    })
    jsonItem["Series24"]=sl;
    jsonItem["Series24DisplayNames"]=sName;
   // mUsers["InternationalMemberFirmTeam"] = m;

    // $.when(retrievePeoplePickerIds(bUsers)).then(function (ppIds) {
    // jsonItem = addPeoplePickerIdsToObject(ppIds, jsonItem)
    // dfd.resolve(jsonItem);
    // })



    // var ppUsers = []
    // //go over all people pickers
    // $.each($('[id^=peoplePickerDiv][id$=_TopSpan]'), function (e) {
    //     var id = $(this).attr('id').split("peoplePickerDiv")[1].split("_TopSpan")[0];
    //     ppUsers[id] = retrievePeoplePickerData(id)
    // })
    // $.when(retrievePeoplePickerIds(ppUsers), retrievePeoplePickerIds(pUsers), retrievePeoplePickerIds(bUsers)).then(function (ppIds, pIds, bIds) {
        jsonItem['IncomeYear1'] = $("#ddlIncomeYear1").val();
        jsonItem['IncomeYear2'] =  $("#ddlIncomeYear2").val();
        jsonItem['IncomeYear3'] = $("#ddlIncomeYear3").val();

        dfd.resolve(jsonItem);
    // })
    return dfd.promise();
}

//scrape the form for data
function retrieveFormData() {
    var dfd = jQuery.Deferred();
    var industryValue = $("#Industry").val();
    // getIndustryRepresentative(industryValue).done(function (dataRepresentative) {
        // if (dataRepresentative) {
        //     var representativeGroupId = dataRepresentative.d.Representative.Id;
        //     var indRepresentative = $("#IndustryRepresentative")
        //     indRepresentative.val(parseInt(representativeGroupId));
        //     indRepresentative.addClass("input-data-text");
        // }
        setJsonItem().done(function (dataJson) {
            dfd.resolve(dataJson);
        });

    // }).fail(
    //     function (error) {
    //         showError();
    //     });
    return dfd.promise()
}


function retrieveIndustryList() {
    var deferred = $.Deferred();
    $.ajax({
        url: apiUrl + "CrossBorderIndustries/GetList",
        method: "GET",
        headers: {
            "accept": "application/json;odata=verbose",
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (data) {
            showError(data);
            deferred.reject();
        }
    })
    return deferred.promise();
}

//fill the responsible branch office selector
function populateIndustry(industryList, value) {
    if (typeof (value) == 'undefined') {
        value = null;
    }

    var htmlData;
    industryList.sort(function(a, b){
        var a1= a.Industry.toLowerCase(), b1= b.Industry.toLowerCase();
        if(a1== b1) return 0;
        return a1> b1? 1: -1;
    });
    var industrySelector = $("#Industry");
    for (var i = 0; i < industryList.length; i++) {
        htmlData += "<option value='" + industryList[i].Industry + "'>" + industryList[i].Industry + "</option>"
    }
    industrySelector.append(htmlData);
    if (value != null) {
        industrySelector.val(value)
    }
}

function retrieveCountryList() {
    var deferred = $.Deferred();
    $.ajax({
        url: apiUrl + "CrossBorderCountries/GetList",
        method: "GET",
        headers: {
            "accept": "application/json;odata=verbose",
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            showError(error);
            deferred.reject();
        }
    })
    return deferred.promise();
}

function populateCountry(countryList, value) {
    if (typeof (value) == 'undefined') {
        value = null;
    }

    var htmlData = "";
    countryList.sort(function(a, b){
        var a1= a.Title.toLowerCase(), b1= b.Title.toLowerCase();
        if(a1== b1) return 0;
        return a1> b1? 1: -1;
    });
    var countrySelector = $("#Country");
    for (var i = 0; i < countryList.length; i++) {
        htmlData += "<option value='" + countryList[i].Title + "'>" + countryList[i].Title + "</option>"
    }

    countrySelector.append(htmlData);
    $(".chosen").chosen();

    if (value != null) {
        countrySelector.val(value).trigger("chosen:updated");
    }
}
$("input.chosen-search-input").bind("paste", function () { //also changed the binding too
    var elem = $(this);
    setTimeout(function () {
     
        $("#peoplePickerDivMemberFirmTeam_chosen .chosen-drop .chosen-results").children().remove();
        $("#peoplePickerDivMemberFirmTeam").children().remove();
        $("#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-drop .chosen-results").children().remove();
        $("#peoplePickerCreateDivMemberFirmTeam").children().remove();
        
          if(elem.val().length>=1)
          {
       callMSUSerGraph(elem.val().toLowerCase().trim());
          }
    }, 10);
});



// $(".chosen-search-input").KeyDown(function() {
   
    
//     $("#peoplePickerDivMemberFirmTeam_chosen .chosen-drop .chosen-results").children().remove();
//      $("#peoplePickerDivMemberFirmTeam").children().remove();
//      $("#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-drop .chosen-results").children().remove();
//      $("#peoplePickerCreateDivMemberFirmTeam").children().remove();
     
//        if($(this).val().length>=1)
//        {
//     callMSUSerGraph($(this).val().toLowerCase().trim());
//        }
//   //callMSUSerGraph($(this).val().toLowerCase().trim());
//   //populatepeoplePickerDivMemberFirmTeam();
   
//   });
  function fillInternationalMemberFirmTeam(internationalMemberFirmTeam)
  {
    var ulselector = $("#peoplePickerDivMemberFirmTeam_chosen .chosen-choices");
    var disName = "";
    if (internationalMemberFirmTeam!= undefined) {
        if (internationalMemberFirmTeam.length > 0) {
            for (i = 0; i < internationalMemberFirmTeam.length; i++) {
                // disName = getDisplayName(dCFEngagementTeam[i]);
                // if (disName)
                if(internationalMemberDisplayName[i]!=undefined)
                {
                    ulselector.prepend('<li class=\"search-choice\" email=' + internationalMemberFirmTeam[i] + '> <span>' + internationalMemberDisplayName[i] + '</span> <a href="#" onclick="remove(this)" class=\"search-choice-close"\ data-option-array-index=' + i + '></a></li>');
                }
                else
                {
                    ulselector.prepend('<li class=\"search-choice\" email=' + internationalMemberFirmTeam[i] + '> <span>' + internationalMemberFirmTeam[i] + '</span> <a href="#" onclick="remove(this)" class=\"search-choice-close"\ data-option-array-index=' + i + '></a></li>');  
                }
                   
            }
        }
    }
  }
  var result = [];
  function populatepeopleNewRegistrationDivMemberFirmTeam() {
    
    result=[];
    $('#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-choices li').each(function() {
       if($(this).text().trim()!=undefined && $(this).text().trim()!="")
       {
        var object = { userPrincipalName: $(this).attr('email'), displayName: $(this).text()};
        result.push(object); 
       } 
    
    });
        var ChosenInputValue = $('#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-search-input').val();
        var ulselector = $("#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-choices");
        
          var htmlData = "";
        var dealTeamBusinessReviewerSelector = $("#peoplePickerCreateDivMemberFirmTeam");
        if(UserGroup!=undefined)
        {
        if(UserGroup.length>=1)
        {
        for (var i = 0; i < UserGroup.length; i++) {
            htmlData += "<option value='" + UserGroup[i].userPrincipalName + "'>" + UserGroup[i].displayName + "</option>"
            $('#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-drop .chosen-results').append("<li  class='active-result' data-option-array-index='"+i+"'>" + UserGroup[i].displayName + "</li>");
            
        }
    }
    else
    {
        htmlData += "<option disabled>No Results Found..</option>"
      
        
    }     
}     
            dealTeamBusinessReviewerSelector.empty().append(htmlData);
           
           $("#peoplePickerCreateDivMemberFirmTeam").trigger("chosen:updated");
          
       
          $('#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-search-input').val(ChosenInputValue);
          if (result!= undefined) {
            result.reverse();
            if (result.length > 0) {
                for (k = 0; k < result.length; k++) {
                        ulselector.prepend('<li class=\"search-choice\" email='+result[k].userPrincipalName+'> <span>'+result[k].displayName+'</span> <a href="#" onclick="remove(this)" class=\"search-choice-close"\ data-option-array-index=' + k + '></a></li>');
                }
            }
        }
        UserGroup=undefined;
   
}
var resultMemberFirmTeam = [];
function populatepeoplePickerDivMemberFirmTeam() {
    resultMemberFirmTeam=[];
    
    $('#peoplePickerDivMemberFirmTeam_chosen .chosen-choices li').each(function() {
       if($(this).text().trim()!=undefined && $(this).text().trim()!="")
       {
        var object = { userPrincipalName: $(this).attr('email'), displayName: $(this).text()};
        resultMemberFirmTeam.push(object); 
       } 
    
    });
        var ChosenInputValue = $('#peoplePickerDivMemberFirmTeam_chosen .chosen-search-input').val();
        var ulselector = $("#peoplePickerDivMemberFirmTeam_chosen .chosen-choices");
        
          var htmlData = "";
        var dealTeamBusinessReviewerSelector = $("#peoplePickerDivMemberFirmTeam");
        if(UserGroup!=undefined)
        {
     if(UserGroup.length>=1)
     {
        for (var i = 0; i < UserGroup.length; i++) {
            htmlData += "<option value='" + UserGroup[i].userPrincipalName + "'>" + UserGroup[i].displayName + "</option>"
            $('#peoplePickerDivMemberFirmTeam_chosen .chosen-drop .chosen-results').append("<li  class='active-result' data-option-array-index='"+i+"'>" + UserGroup[i].displayName + "</li>");
            
        }
    }
        else
        {
            htmlData += "<option disabled>No Results Found..</option>"
          
            
        } 
    }       
            dealTeamBusinessReviewerSelector.empty().append(htmlData);
           
           $("#peoplePickerDivMemberFirmTeam").trigger("chosen:updated");
          
       
          $('#peoplePickerDivMemberFirmTeam_chosen .chosen-search-input').val(ChosenInputValue);
          if (resultMemberFirmTeam!= undefined) {
            resultMemberFirmTeam.reverse();
            if (resultMemberFirmTeam.length > 0) {
                for (k = 0; k < resultMemberFirmTeam.length; k++) {
                        ulselector.prepend('<li class=\"search-choice\" email='+resultMemberFirmTeam[k].userPrincipalName+'> <span>'+resultMemberFirmTeam[k].displayName+'</span> <a href="#" onclick="remove(this)" class=\"search-choice-close"\ data-option-array-index=' + k + '></a></li>');
                }
            }
        }
        UserGroup=undefined;
   
}
function populateEngagementTeam(dcfAdUsersList, value, id) {
    //if(typeof(value) == 'undefined'){
    if (value == null) {
        var htmlData = "";
        var engagementTeamSelector = $("#DCFEngagementTeam");
        var dealTeamBusinessReviewerSelector = $("#DealTeamBusinessReviewer");
        var series24Selector=$("#Series24");
        if(id=="Series24"){
            for (var i = 0; i < dcfAdUsersList.length; i++) {
                htmlData += "<option value='" + dcfAdUsersList[i].Email + "'>" + dcfAdUsersList[i].LastName+', '+dcfAdUsersList[i].FirstName + "</option>"
            }
        }
        else
        {
        for (var i = 0; i < dcfAdUsersList.length; i++) {
            
            htmlData += "<option value='" + dcfAdUsersList[i].userPrincipalName + "'>" + dcfAdUsersList[i].displayName + "</option>"
        }
    }

        if (id == "DCFEngagementTeam") {
            engagementTeamSelector.append(htmlData);
            $("#DCFEngagementTeam").trigger("chosen:updated");
        }
        else if (id == "DealTeamBusinessReviewer") {
            dealTeamBusinessReviewerSelector.append(htmlData);
            $("#DealTeamBusinessReviewer").trigger("chosen:updated");
        }
        else if(id=="Series24"){
            series24Selector.append(htmlData);
            $("#Series24").trigger("chosen:updated");

    }
}
   
}

function fillSeries24(series24) {

    populateEngagementTeam(series24List, null, "Series24");
    var ulselector = $("#Series24_chosen .chosen-choices");
    var disName = "";
    var s24_Flag=false;
    if (series24 != undefined) {
        if (series24.length > 0) {
            for (i = 0; i < series24.length; i++) {
                // disName = getDisplayName(series24[i]);
                // if (disName)
                //     ulselector.prepend('<li class=\"search-choice\" email=' + series24[i] + '> <span>' + disName + '</span> <a href="#" onclick="removeS24(this)" class=\"search-choice-close"\ data-option-array-index=' + getIndexValue(disName) + '></a></li>');
                if(Series24DisplayNames[i]!=undefined)
                    ulselector.prepend('<li class=\"search-choice\" email=' + series24[i] + '> <span>' + Series24DisplayNames[i] + '</span> <a href="#" onclick="remove(this)" class=\"search-choice-close"\ data-option-array-index=' + i + '></a></li>');
                else
                    ulselector.prepend('<li class=\"search-choice\" email=' + series24[i] + '> <span>' + series24[i] + '</span> <a href="#" onclick="remove(this)" class=\"search-choice-close"\ data-option-array-index=' + i + '></a></li>');  
            }
        }
    }
    if(series24List.length>0){
        for (i = 0; i < series24List.length; i++) { 
         if(series24List[i].Email==disName||disName==series24List[i].LastName+", "+series24List[i].FirstName||disName=="")
             s24_Flag=true;
        }
        if(!s24_Flag){
           // notS24GroupItem=disName;
            $("#S24SuggestionId").css("display","block");
        }
        else{
            $("#S24SuggestionId").css("display","none");
        }
    }
    if(disName!=""){$('#Series24_chosen .chosen-search-input').css("color","transparent")}
        
}
$("#peoplePickerCreateDivMemberFirmTeam_chosen").keydown(function(e) {
    var ChosenInputValue = $('#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-search-input').val();
    if(ChosenInputValue==""||ChosenInputValue==null) {
    if (e.keyCode == 8 ) {
        e.preventDefault();
    }
  }
});
$("#peoplePickerDivMemberFirmTeam_chosen").keydown(function(e) {
    var ChosenInputValue = $('#peoplePickerDivMemberFirmTeam_chosen .chosen-search-input').val();
   if(ChosenInputValue==""||ChosenInputValue==null) {
     if (e.keyCode == 8 ) {
         e.preventDefault();
     }
    }
 });
function fillDcfEngagementTeam(dCFEngagementTeam) {


    populateEngagementTeam(adUserGroup, null, "DCFEngagementTeam");
    var ulselector = $("#DCFEngagementTeam_chosen .chosen-choices");
    var disName = "";
    if (dCFEngagementTeam!= undefined) {
        if (dCFEngagementTeam.length > 0) {
            for (i = 0; i < dCFEngagementTeam.length; i++) {
                disName = getDisplayName(dCFEngagementTeam[i]);
                if (disName)
                    ulselector.prepend('<li class=\"search-choice\" email=' + dCFEngagementTeam[i] + '> <span>' + disName + '</span> <a href="#" onclick="remove(this)" class=\"search-choice-close"\ data-option-array-index=' + getIndexValue(disName) + '></a></li>');
            }
        }
    }
}

function fillDealTeamBusinessReviewer(dealTeamBusinessReviewer) {

    populateEngagementTeam(adUserGroup, null, "DealTeamBusinessReviewer");
    var ulselector = $("#DealTeamBusinessReviewer_chosen .chosen-choices");
    var disName = "";

    if (dealTeamBusinessReviewer != undefined) {
        if (dealTeamBusinessReviewer.length > 0) {
            for (i = 0; i < dealTeamBusinessReviewer.length; i++) {
                disName = getDisplayName(dealTeamBusinessReviewer[i]);
                if (disName)
                    ulselector.prepend('<li class=\"search-choice\" email=' + dealTeamBusinessReviewer[i] + '> <span>' + disName + '</span> <a href="#" onclick="remove(this)" class=\"search-choice-close"\ data-option-array-index=' + getIndexValue(disName) + '></a></li>');
            }
        }
    }
}
function remove(link) {
    link.parentNode.parentNode.removeChild(link.parentNode);
    $(".chosen-drop").hide();

}
function removeS24(link) {
    link.parentNode.parentNode.removeChild(link.parentNode);
    $(".chosen-drop").hide();
   var deletedItem=link.parentElement.innerText.trim().toLowerCase(); 
   var ulselector = $("#Series24_chosen li.search-choice");
 if(ulselector.length==0){$('#Series24_chosen .chosen-search-input').css("color","grey");}
//  if(deletedItem==notS24GroupItem.toLowerCase()){
//     $("#S24SuggestionId").css("display","none");
//    }
}

function getDisplayName(email) {
    return adUserGroup.find(o => o.userPrincipalName.toLowerCase() === email.toLowerCase())?.displayName ?? email;
}

function getIndexValue(userDisplayName) {
    return adUserGroup.findIndex(o => o.displayName.toLowerCase() === userDisplayName.toLowerCase()) + 1;
    //var i=0;
    // for (i = 0; i <adUserGroup.length ; i++) {
    // if(adUserGroup[i].displayName === userDisplayName)
    // return i+1;
    // }
}

//$( "#EngagementTeamId" ).autocomplete({
//     source: function( request, response ) {
//       // $.ajax( {
//         // url: "search.php",
//         // dataType: "jsonp",
//         // data: {
//           // term: request.term
//         // },
//         // success: function( data ) {
//           // response( data );
//         // }
//       // } );
//	var filteredData = adUserGroup.filter(function (objVal) {
//       return objVal.userPrincipalName.toLowerCase().includes(request.term.toLowerCase())|| objVal.displayName.toLowerCase().includes(request.term.toLowerCase());
//   });
//	var data={};
//	for (var i = 0; i < filteredData.length ; i++) {
//       data[i]=filteredData[i].displayName;
//	}
//	response(data);
//     },
//     minLength: 2,
//     select: function( event, ui ) {
//     }
//   } );


function getTemplates(documentTypeId) {
    var deferred = $.Deferred();
    var documentType='';
    if(documentTypeList != undefined && documentTypeList.length > 0)
    {
        var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId && obj.isActive!="No");
        if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
            documentType = docTypeObj[0].DocumentTypeTitle;
    }
    $.ajax({
        url: apiUrl + "CrossBorderTemplates/GetList?documentType="+documentType,
        type: "GET",
        headers: {
            "accept": "application/json",
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            //console.log(data);
            deferred.resolve(data);
        },
        error: function (error) {
            showError(error);
        }
    })
    return deferred.promise();
}

function getRecommendedTemplates(documentTypeId) {
    var deferred = $.Deferred();
    var formId = GetURLParameter('item_id');
    var documentType='';
    var recUrl='';
    if(documentTypeId!=null)
    {
        if(documentTypeList != undefined && documentTypeList.length > 0)
        {
            var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId);
            if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
                documentType = docTypeObj[0].DocumentTypeTitle;
        }
    }
    if(documentType=='')
        recUrl=apiUrl + "CrossBorderRecommendedTemplates/GetList?formID="+formId;
    else
        recUrl=apiUrl + "CrossBorderRecommendedTemplates/GetList?formID="+formId+"&documentType="+documentType;

    // var folderUrl = createFolderUrl(attachRecommendedSectionEnviroment, formId);
    $.ajax({
        url: recUrl,
        type: "GET",
        headers: {
            "accept": "application/json",
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            showError(error);
        }
    })
    return deferred.promise();
}

function fillTemplates(data) {
    var htmlData = '';
    for (var key in data.d.results) {
        var url = encodeURIComponent(data.d.results[key].ServerRelativeUrl.replace(siteRelativeUrl, ""));
        var name = data.d.results[key].Name;
        htmlData += "<li><div class='display-flex'><a href=" + apiUrl + url + " download='" + name + "' target='_blank'><div class='glyphicon glyphicon-file icon-file-color'></div></a><div class='width-93 padding-left-2 padding-bottom-5'><a href=" + apiUrl + url + " download='" + name + "' target='_blank'>" + name + "</a></div></div></li>";
    }
    if (htmlData != '') {
        $('#templates-section').find('ul.file-list').append(htmlData);
    }
}

function getInputName(key, items) {
    var item = $('[name=' + items[key] + ']');
    if (item.length == 0) {
        item = $('[id=' + 'peoplePickerDiv' + items[key] + ']');
        if (item.length == 0) {
            item = $('[name=' + items[key] + 'Id' + ']');
        }
    }
    return item;
}

function addClassToRequiredFields(data) {
    var items = data[0].RequiredFields;
    if(displayNewquestions)
        {
            items.push("ClientTypeXB");        
        }
    items.push(data[0].RequiredGroupFields);
    for (var key in items) {
        var item = getInputName(key, items);
        var parentItem = item.parents(".required-div");
        item.addClass("required");
        parentItem.find(".red-asterik").removeClass("visibility-hidden");
    }
}
function populateRequiredFields() {
    var deferred = $.Deferred();
    $.ajax({
        url: apiUrl+"CrossBorderValidationFields/GetList",
        method: "GET",
        headers: {
            "accept": "application/json;odata=verbose",
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            showError(error);
            deferred.reject();
        }
    })
    return deferred.promise();
}

// function populateRequiredFields(value) {
//     $.ajax({
//         url: apiUrl+"CrossBorderValidationFields/GetList",
//         method: "GET",
//         headers: {
//             "accept": "application/json;odata=verbose",
//             "Authorization" : getTokenValue(),
//             "FormType" : getFormType(),
//             "UserRoles": getUserRoles()
//         },
//         success: function (data) {
//             addClassToRequiredFields(data);
//         },
//         error: function (data) {
//             showError();
//         }
//     })
// }

function haveMissingRequiredDocumentTypes() {
    var allRequiredDocuments = $("[document-required]").toArray();
    var areMissingRequiredDocuments = allRequiredDocuments.some(function (objVal) {
        if (!haveDocumentTypeAttachments(objVal.getAttribute("document-type"))) {
            return true;
        }
    });
    return areMissingRequiredDocuments;
}

function validateRequiredFinalVersions() {
    var id = $('button.formsub').not('.hidden').attr('id');
    if (id == 'submitS24Button') {
        return allFinalVersionAreMarked();
    }
    return true;
}

function getMissingRequiredFields() {
    var invalidFields = [];
    $.each($('.required'), function () {
        var input = $(this);
        if (input.is(':radio')) {
            var inputname = input.attr('name');
            input = input.parents('.required-div').find('.required:radio').filter(':checked');
            if (input.length == 0) {
                invalidFields.push(inputname);
            }
        }
        else if (input.is(':checkbox')) {
            var inputname = input.attr('name');
            input = input.parents('.required-div').find('.required:checkbox').filter(':checked');
            if (input.length == 0) {
                invalidFields.push(inputname);
            }
        }
        else if (input.hasClass('input-data-text')) {
            if (input.val().trim() == '') {
                invalidFields.push(input.attr('name'))
            }
        }
        else if (input.hasClass('input-data-select')) {
            if (input.val() == null) {
                invalidFields.push(input.attr('name'))
            }
        }
        else if (input.hasClass('input-data-person')) {
            if (input.find('span.sp-peoplepicker-userSpan').length == 0) {
                invalidFields.push(input.attr('id').split("peoplePickerDiv")[1])
            }
        }
        else if (input.hasClass('chosen-select-no-results')) {

            if (input.siblings('.chosen-container:visible').find('ul .search-choice').length == 0) {
                invalidFields.push(input.attr('id'))
            }
        }
        var documentTypeRequired = $("#required-DocumentTypes").attr("verify-document-types");

        var documentTypeRequired = $("#required-DocumentTypes").attr("verify-document-types");
        if (documentTypeRequired && haveMissingRequiredDocumentTypes()) {
            invalidFields.push("document-section");
        }
        var isPrivateEntity = $('#privateType').is(':checked');        
        if(isPrivateEntity) 
        {
            // Check if there are any <li> items under the specified <ul> elements
            if ($('#attachment-section-financial-type .file-list li').length === 0) {
                invalidFields.push('income-statement-1');
            }
            if ($('#attachment-section-client-capitalization .file-list li').length === 0) {
                invalidFields.push('income-statement-2');
            }
        }
    })

    if (haveMissingIncomeRequiredColumns()) {
        invalidFields.push("IncomeStatement");
    }

    return invalidFields;
}

function getInvalidTextFields() {
    var invalidFields = [];
    $.each($('.input-data-text'), function () {
        var input = $(this);
        if (!input.is('textarea') && input.val().trim().length > 255) {
            invalidFields.push(input.attr('name'));
        }
    });
    return invalidFields;
}

function highlightMissingRequiredFields() {
    highlightRequiredFields(getMissingRequiredFields());
}

function highlightRequiredFields(missingFields) {
    cleanMissingRequiredFields();
    for (var field in missingFields) {
        var input;
        if(missingFields[field] == "income-statement-1" || missingFields[field] == "income-statement-2")
        {         
         input = $('[id='+ missingFields[field] + ']');
        }
        else
          input = getInputName(field, missingFields);
        var parentItem = input.parents(".required-div");
        parentItem.find(".span-to-highlight").addClass("bg-missingRequiredField");
    }
}

function cleanMissingRequiredFields() {
    $.each($('.bg-missingRequiredField'), function () {
        $(this).removeClass('bg-missingRequiredField');
    })
}

function haveMissingIncomeRequiredColumns() {
    var incomeRequiredColumns = $(".income-required-column").toArray();
    var missingFields = incomeRequiredColumns.some(function (objVal) {
        if (objVal.value.trim() == '') {
            return true;
        }
    });
    return missingFields;
}

$("#income-statement-section .fy-validation .input-data-integer").blur(function () {
    var input = $(this);
    var fyNumericValidation = new FyNumericValidation(input);
    fyNumericValidation.validate();
})

$('#income-statement-section .fy-validation .input-data-integer').on('keypress', function (e) {
    return allowNumericInputOnKeypress(false, e);
})

$('#income-statement-section .fy-validation .input-data-integer').on('change', function (e) {
    var input = $(e.target);
    allowNumericInputOnChange(input, false);
    input.attr("numeric-value", input.val());
})

$("#income-statement-section .revenue-validation .input-data-currency").blur(function () {
    var input = $(this);
    var revenueNumericValidation = new RevenueNumericValidation(input);
    revenueNumericValidation.format();
    revenueNumericValidation.validate();
})

$('#income-statement-section .revenue-validation .input-data-currency').on('keypress', function (e) {
    return allowNumericInputOnKeypress(false, e);
})

$('#income-statement-section .revenue-validation .input-data-currency').on('change', function (e) {
    var input = $(e.target);
    allowNumericInputOnChange(input, false);
    input.attr("numeric-value", input.val());
})

$("#income-statement-section .revenue-pct-validation .input-data-float").blur(function () {
    var input = $(this);
    var revenuePctNumericValidation = new RevenuePctNumericValidation(input);
    if(revenuePctNumericValidation.validate())
    {
        revenuePctNumericValidation.format(); 
    }       
})

$('#income-statement-section .revenue-pct-validation .input-data-float').on('keypress', function (e) {
    return allowNumericInputOnKeypress(true, e);
})

$('#income-statement-section .revenue-pct-validation .input-data-float').on('change', function (e) {
    var input = $(e.target);
    allowNumericInputOnChange(input, true);

    //after decimal 0 is entered then ignore it.
    var inputdata = input.val().trim().split('.');
    var numericValue = input.val().trim();
    if(inputdata.length > 1 && inputdata.length < 3 && inputdata[1] == 0)
    {
        numericValue = inputdata[0];
    }
    input.attr("numeric-value", numericValue);
})

//condition checkbox
$('.condition-checkbox').change(function (e) {
    var checkbox = $(this);
    var text = checkbox.siblings('input:text,.input-data-text');
    if (checkbox.is(':checked')) {
        if (text.hasClass('required-when-checked')) {
            enableInput(text);
        }
    }
    else {
        text.val('');
        if (text.hasClass('required-when-checked')) {
            disableInput(text);
        }
    }
});

function enableInput(text) {
    var parentItem = text.parents(".required-div");
    text.prop('disabled', false)
    text.addClass("required");
    parentItem.find(".required-span-when-checked").removeClass("visibility-hidden");
}

function disableInput(text) {
    var parentItem = text.parents(".required-div");
    text.prop('disabled', true)
    text.removeClass("required");
    parentItem.find(".required-span-when-checked").addClass("visibility-hidden");
}

function initializeDisabledInputs() {
    $.each($(".condition-checkbox"), function () {
        var checkbox = $(this);
        var input = checkbox.siblings('input:text,.input-data-text');
        if (checkbox.is(':checked')) {
            enableInput(input);
        }
        else {
            disableInput(input);
        }
    })
}

//Add users to group
function addUsersToGroup(groupId, users) {
    var deferred = $.Deferred();
    var ajaxCalls = users.length;
    var result = [];
    if (ajaxCalls > 0) {
        for (var i in users) {
            var userEmail = users[i].Email;
            addUserToGroup(groupId, userEmail).done(function (data) {
                result.push(data);
                ajaxCalls--;
                if (ajaxCalls == 0) {
                    deferred.resolve(result);
                }
            })
                .fail(function (error) {
                    showError(error);
                });
        }
    } else {
        deferred.resolve(result);
    }
    return deferred.promise();
}

//giving permissions to member firm team
function addMemberFirmTeam() {
    var deferred = $.Deferred();
    var memberFirmTeamGroup = "DCF_CrossBorder_MemberFirmTeam";
    $.when(getGroupId(memberFirmTeamGroup)).then(function (groupData) {
        var PPname = "MemberFirmTeam";
        var users = [];
        users = retrievePeoplePickerData(PPname);
        $.when(addUsersToGroup(memberFirmTeamGroup, users)).then(function (data) {
            deferred.resolve(data);
        });
    });
    return deferred.promise();
}

// function getIndustryRepresentative(industryId) {
//     var deferred = $.Deferred();
//     if (industryId) {
//         $.ajax({
//             url: siteUrl + "/_api/web/lists/getbytitle('" + industryList + "')/items(" + industryId + ")/?$expand=Representative/Title&$Select=Representative/Title,Representative/Id",
//             method: "GET",
//             headers: {
//                 "accept": "application/json;odata=verbose",
//             },
//             success: function (data) {
//                 deferred.resolve(data);
//             },
//             fail: function (data) {
//                 deferred.reject();
//             }
//         })
//     }
//     else {
//         deferred.resolve();
//     }
//     return deferred.promise();
// }

//POST
function apiPost() {
    var deferred = $.Deferred();
            $.when(retrieveFormData()).then(function (jsonItem) {
                $.ajax({
                    url: apiUrl + "web/lists/getbytitle('" + enviroment + "')/items",
                    type: "POST",
                    contentType: "application/json;odata=verbose",
                    data: JSON.stringify(jsonItem),
                    headers: {
                        "accept": "application/json;odata=verbose",
                        "X-RequestDigest": $("#__REQUESTDIGEST").val(),
                        "content-Type": "application/json;odata=verbose"
                    },
                    success: function (data) {
                        /*gblFormId = data.d.ID
                        showModalRedirectOrSave(gblFormStatus);*/
                        deferred.resolve(data);
                    },
                    error: function (data) {
                        showError(error);
                    }
                })
            })
    return deferred.promise();
}

//create empty form data for item submitting
function emptyPost() {
    var jsonItem = { __metadata: { 'type': 'SP.Data.' + enviroment + 'ListItem' }, 'FormStatus': 'Draft' };
    return $.ajax({
        url: apiUrl + "web/lists/getbytitle('" + enviroment + "')/items",
        type: "POST",
        contentType: "application/json;odata=verbose",
        data: JSON.stringify(jsonItem),
        headers: {
            "accept": "application/json;odata=verbose",
            "X-RequestDigest": $("#__REQUESTDIGEST").val(),
            "content-Type": "application/json;odata=verbose"
        }
    });
}

//update reactivated status data 
function reactivateFormPost(previousStatus) {
   
    var jsonItem = { };
    if (previousStatus === 'Discontinued') {
        jsonItem['ReactivatedBy'] = currentUser.email;
        jsonItem['ReactivatedOn'] = new Date();
        jsonItem['FormActionGroup']=gblFormGroup;
        
    }
    if (previousStatus === 'OnHold') {
        jsonItem['PutOnHoldReactivatedBy'] = currentUser.userId;
        jsonItem['PutOnHoldReactivatedOn'] = new Date();
        jsonItem['FormActionGroup']=gblFormGroup;
        jsonItem['ReactivatedBy'] = currentUser.email;
    }
    if(gblFormId!=""&&gblFormId!=undefined)
    {
        jsonItem['ID'] = gblFormId;
        jsonItem['Created'] = CreatedDate;
        jsonItem['FormStatus'] ="Pending";
    }
    if(previousStatus === 'Discontinued' && gblFormGroup=="SecondSubmissionTeam")
    {
        jsonItem['FormStatus'] = "Pending";  
        jsonItem['FormActionGroup']=gblFormGroup;
    }
    if(gblFormGroup=="IndustryRepresentative")
    {
        jsonItem['FormStatus'] = "Pending";  
        jsonItem['FormActionGroup']=gblFormGroup;
    }
    if(gblFormGroup=="MemberFirmTeam")
    {
        jsonItem['FormStatus'] = "Draft";  
        jsonItem['FormActionGroup']=gblFormGroup;
    }
    if(gblFormGroup=="BusinessQRMCompliance")
    {
        jsonItem['FormStatus'] = "Pending";  
        jsonItem['FormActionGroup']=gblFormGroup;
    }
    $.each($(".input-data-date"), function () {
        var inputdata = $(this);
        var inputVal = inputdata.val();
        if (inputVal != '') {
           if(typeof(inputdata.attr("approvalDate")) == "string" && inputdata.attr("approvalDate").includes("/"))
            {
                dateToSent = inputdata.attr("approvalDate");
            }
            else
            {
                dateToSent = new Date(inputdata.attr("approvalDate"));
            }
        }
        else {
            dateToSent = null;

        }
        jsonItem[inputdata.attr('name')] = dateToSent;
    })

    $.each($(".input-data-text"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val();
    })

    $.each($(".input-data-float"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val()
    })

    $.each($(".input-data-integer"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val()
    })

    $.each($(".input-data-currency"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val();
       // jsonItem[inputdata.attr('name')] = formatNumberToSend(inputdata.val(), false);
    })

    //go over all checkboxes section
    $.each($(".checkbox-section"), function () {
        var CheckedResults = [];
        var checkBoxSection = $(this);

        $.each(checkBoxSection.find($(".input-data-checkbox")), function () {
            var inputdata = $(this);
            if (inputdata.is(':checked')) {
                CheckedResults.push(inputdata.val());
            }
        })
        jsonItem[checkBoxSection.attr('id')] = CheckedResults;
    })

    //go over all radios
    $.each($(".input-data-option"), function () {
        var inputdata = $(this);
        if (inputdata.is(':checked')) {
            jsonItem[inputdata.attr('name')] = inputdata.val();
        }
        else {
            if (!jsonItem[inputdata.attr('name')]) {
                jsonItem[inputdata.attr('name')] = null;
            }
        }
    })

    //go over all selects
    $.each($(".input-data-select"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val();
    })

    var pUsers = [];
    var arr = [];
    var DCFEngagementTeamDisplayNames=[];
    $('#DCFEngagementTeam_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        arr.push( $(item).attr('email'));
        DCFEngagementTeamDisplayNames.push(item.innerText)
    })
    pUsers["DCFEngagementTeam"] = arr;

    // $.when(retrievePeoplePickerIds(pUsers)).then(function (ppIds) {
    // jsonItem = addPeoplePickerIdsToObject(ppIds, jsonItem)
    // dfd.resolve(jsonItem);
    // })

    var bUsers = [];
    var l = [];
    var DealTeamBusinessReviewerName=[];
    $('#DealTeamBusinessReviewer_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        l.push($(item).attr('email'));
        DealTeamBusinessReviewerName.push(item.innerText);
    })
    bUsers["DealTeamBusinessReviewer"] = l;
    var m = [];
    var MemberFirmTeamName = []
    $('#peoplePickerDivMemberFirmTeam_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        m.push($(item).attr('email'))
        MemberFirmTeamName.push(item.innerText);
    })
    var s = [];
    var sName = [];
    $('#Series24_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        s.push($(item).attr('email'));
        sName.push(item.innerText);
    })
    jsonItem["InternationalMemberFirmTeam"]=m
    jsonItem["InternationalMemberFirmTeamDisplayNames"]=MemberFirmTeamName
    
    jsonItem["DealTeamBusinessReviewer"]=l;
    jsonItem["DealTeamBusinessReviewerDisplayNames"]=DealTeamBusinessReviewerName;
    jsonItem["DCFEngagementTeam"]=arr;
    jsonItem["DCFEngagementTeamDisplayNames"]=DCFEngagementTeamDisplayNames;
    jsonItem["Series24"]=s;
    jsonItem['CreatedBy']=CreatedBy;
    jsonItem['ModifiedBy']=localStorage.getItem("userProfileName");

    jsonItem["Series24DisplayNames"]=sName;
    return $.ajax({
        url:apiUrl+"CrossBorderForm/Update",
        type: "POST",
        headers: {
            "content-Type": "application/json;odata=verbose",
            "Authorization" : getTokenValue(),
            "FormType" : getFormType(),
            "UserRoles": getUserRoles()
        },
        data: JSON.stringify(jsonItem),
        success: function () {
            hideSpinner();
            redirectOkModal();
        },
        error: function (data) {
            hideSpinner();
            showError(data);
        }
    });
}

//PUT
function apiPut(formId) {
    var deferred = $.Deferred();
    // SP.SOD.executeFunc('sp.js', 'SP.ClientContext', function () {
    //     UpdateFormDigest(siteRelativeUrl, _spFormDigestRefreshInterval)
    //     $.when(addMemberFirmTeam()).then(function (result) {
            $.when(retrieveFormData()).then(function (jsonItem) {
                $.ajax({
                    url:apiUrl+"CrossBorderForm/Update",
                    type: "POST",
                    headers: {
                        "content-Type": "application/json",
                        "Authorization" : getTokenValue(),
                        "FormType" : getFormType(),
                        "UserRoles": getUserRoles()
                    },
                    data: JSON.stringify(jsonItem),
                    success: function (data) {
                        hideSpinner();
                        deferred.resolve();
                    },
                    error: function (data) {
                        hideSpinner();
                        showError(data);
                    }
                })
            })
    return deferred.promise();
}

function getItem(id) {        
        $.ajax({
           url: apiUrl+"CrossBorderForm/GetFormDetails?formID="+id,
            method: "GET",
            headers: {
                "accept": "application/json;odata=verbose",
                "Authorization" : getTokenValue(),
                "FormType" : getFormType(),
                "UserRoles": getUserRoles()
            },
            success: function (data) {
                if(data.isActive!=undefined && data.isActive == "No")
                {
                    localStorage.setItem("IsInvalidForm",true);
                    $('#s4-workspace').css("pointer-events","auto");
                    showModalMessage(formisRemoved);
                }
                else{
                    if(data.FormActionGroup!=null || data.FormActionGroup!=undefined)
                    {
                    var formData = data;
                    CreatedDate=data.Created;
                    checkCreatedDate();
                    var formGroup = data.FormActionGroup;
                    var industryListItem;
                    var countrytryListItem;
                    var industryId;
                    var requiredFieldList;
                    adUserGroup;
                    gblFormStatus =formData.FormStatus;
                    gblFormId = id;
                    xbProjectName = formData.ProjectName;
                    xbFormId=gblFormId;
                    gblFormGroup = formGroup;
                    if(formData.CreatedBy!=undefined || formData.CreatedBy!=null)
                    {
                        CreatedBy=formData.CreatedBy;
                    }
                    else
                    {
                        CreatedBy=localStorage.getItem("userProfileName");
                    }
                    
                    gblSchedule3DocumentRequired = data.Schedule3DocumentRequired;
                    // Set dropdown selected values
                    if (data.IncomeYear1 != null) {
                        $("#ddlIncomeYear1").val(data.IncomeYear1);
                    }
                    if (data.IncomeYear2 != null) {
                        $("#ddlIncomeYear2").val(data.IncomeYear2);
                    }
                    if (data.IncomeYear3 != null) {
                        $("#ddlIncomeYear3").val(data.IncomeYear3);
                    }
                    if(gblFormGroup=="Series24")
                    {
                        CompanySubsectorComments=data.CompanySubsectorComments;
                        ShareholderObjectivesComments=data.ShareholderObjectivesComments;
                        GeographConcentrationComments=data.GeographConcentrationComments;
                        ClientAffiliateComments=data.ClientAffiliateComments;
                        USCounterpartiesContactComments=data.USCounterpartiesContactComments;
                        ExpectedCompanyValueComments=data.ExpectedCompanyValueComments;
                    }
                    if(data.ClientTypeXB=="Private")
                        {
                            $('#privateType').prop('checked',true);
                            $('#publicType').prop('checked',false);
                        }
                        else if(data.ClientTypeXB=="Public")
                        {
                                $('#privateType').prop('checked',false);
                                $('#publicType').prop('checked',true);
                        }
                        toggleRedAsterisk();
             
                    $.when(retrieveIndustryList(),retrieveCountryList(),getCurrentUserFormGroup(),getCurrentUserGroups(),populateRequiredFields()).done(function(industryList,countryList,dataGroup,s24List,requiredFilds){
                        industryList=applyContentManagementRole(industryList,data.Industry,"Industry");
                        countryList=applyContentManagementRole(countryList,data.Country,"Country");
                        industryListItem=industryList;
                        countrytryListItem=countryList;
                        if(data.Industry!=null && data.Industry!=undefined){
                      var industry =industryList.filter(x=>x.Industry==data.Industry);
                     industryId=industry[0].Representative
                        }
                            userGroups = dataGroup;
                            series24List=s24List;
                            requiredFieldList=requiredFilds;
                            $.when(getUserSecurityContext(industryId, userGroups, formData, formGroup, adUserGroup,currentUser.email)).done(function (securityContextData) {
                                securityContext = securityContextData;
                                isXBAdmin = securityContext.isXBAdmin();
                                if(isXBAdmin)
                                {
                                    $.when(getAttachmentAuditHistoryCount(gblFormId)).then(function(count){
                                        if(count!=null && count > 0)
                                        {
                                            $("#AuditFormButton").prop('disabled',false);
                                        }
                                    });
                                }                          
                                
                                $.when(authenticateUser(securityContext, formData)).done(function () {
                                     $.when(
                                            getFiles(gblFormId),
                                            getRedLineFiles(gblFormId),
                                            getDocumentStatus(gblFormId),
                                            getDocumentComments(gblFormId)
                               ).done(
                                            function (atachments,redLineAttachments, reviewData, commentsData) {
                                                hasSchedule3Attachment = true;
                                                $.when(populateDocumentTypes(gblFormStatus,atachments)).done(function () {
                                                    reviewProcessContext = createReviewProcessContext();
                                                    $.when(reviewProcessContext.calculateReview(gblFormId,reviewData)).done(function (reviewProcessContextData) {
                                                            additionalDocTypesList=documentObj.filter(x =>x.Required == "FALSE");
                                                            let financeAttachments = atachments.filter(x=>x.AttachmentType == "Finance-Statements");
                                                            let capitalizationAttachments = atachments.filter(x=>x.AttachmentType == "Client-Capitalizations");
                                                            if(financeAttachments && financeAttachments.length > 0)
                                                            {
                                                                generateAttachmentsFinanceDocSection(financeAttachments,attachEnviroment);
                                                            }
                                                            if(capitalizationAttachments && capitalizationAttachments.length > 0)
                                                            {
                                                                generateAttachmentsCapitalizationDocSection(capitalizationAttachments,attachEnviroment);
                                                            }
                                                            generateAttachments(atachments, attachEnviroment);
                                                            generateRedLinesAttachments(redLineAttachments);
                                                            populateStatusIconDocumentType(reviewData);
                                                            populateCommentsDocumentType(commentsData, securityContext.isMemberFirmMember,atachments);
                                                            fillForm(formData, countrytryListItem, adUserGroup, industryListItem,series24List,requiredFieldList);
                                                            formatSignatureDates();
                                                            fillMaxLengthComments(appContext.maxLengthComments);
                                                            applySecurity(securityContext, reviewProcessContextData, gblFormStatus, formGroup,reviewData);
                                                            
                                                            hideSpinner();
                                                            
    
                                                        });
                                                       
                                                        $("#test").css({ position: "absolute"});
        
                                                        $('#s4-workspace').css("pointer-events","auto");
                                                       showModalMessage(formLoadOkMessage);
                                                       
            
                                                    });
                                          });
                                              });
                                });
                            })
                       // hideSpinner();
                  
                    if(data.Status=='Review in progress' || data.Status=='On Hold' || data.Status=='Discontinued' || data.Status=='Completed' ){
                        $("#ddlIncomeYear1").css('pointer-events','none');
                        $("#ddlIncomeYear2").css('pointer-events','none');
                        $("#ddlIncomeYear3").css('pointer-events','none');
                    }
                    }
                    else
                    {
                        redirectCustomList();
                        $('#s4-workspace').css("pointer-events","auto");
                        showModalMessage(formidinvalid);
                    }
                }              
            },
            error: function (data) {
                $('#s4-workspace').css("pointer-events","auto");
                showError(data);
                hideSpinner();
            }
        })    
}

function applyContentManagementRole(data,selectedValue,propName){
    var resData=[];
    try{
          switch(propName){
            case"Industry":
            data.filter(x=>{
              if(selectedValue==null||selectedValue==undefined||selectedValue==""){
                if(x.isActive!="No")
                  resData.push(x);
              }
              else{
                 if(x.Industry==selectedValue){
                    resData.push(x);
                 }
                 else{
                    if(x.isActive!="No")
                      resData.push(x);
                 }
              }
            });
              return resData;
            break;
            case"Country":
            data.filter(x=>{
                if(selectedValue==null||selectedValue==undefined||selectedValue==""){
                  if(x.isActive!="No")
                    resData.push(x);
                }
                else{
                   if(x.Title==selectedValue){
                      resData.push(x);
                   }
                   else{
                      if(x.isActive!="No")
                        resData.push(x);
                   }
                }
              });
                return resData;
            break;
            default:
                //statements
                return data;
            break;
          }
       
    }
    catch(e){
      return data;
    }
}
function handleResponse(resp) {
    if (resp !== null) {

        accountId = resp.account.homeAccountId;
        if(GetURLParameter('item_id') == null)
        {
            //window.location.href=appUrl;
            getAdNewUserData();
        }
        else
        {
            getAdGroupData();
        }
       
    } else {
        const currentAccounts = myMSALObj.getAllAccounts();
        if (!currentAccounts || currentAccounts.length > 1) {
            signIn();
            getAdGroupData();
        }
        else {
            signIn();
            getAdGroupData();
        }
    }
}

function updateUI(data, endpoint) {
    adUserGroup = data.value;
   var id= GetURLParameter('item_id');
   if(localStorage.getItem('CurrentAccessPage')=="CrossBorderForm")
    {
        if(id!="" && id!=undefined)
        {
            getItem(id);
            //$("#onetrust-consent-sdk").css("display", "none");
        }
        else
        {
            redirectCustomList();
            $('#s4-workspace').css("pointer-events","auto");
            showModalMessage(formidinvalid);
        }
    }
}
function getusergroup(data) {
   // UserGroup=data.value;
    UserGroup=data.value.filter(obj => obj.mail);
    UserGroup=UserGroup.filter(obj => obj.mail.toLowerCase().includes('@deloitte') || obj.mail.toLowerCase().includes('@tohmatsu'));
    if(FromLevel=="New")
    {
        populatepeopleNewRegistrationDivMemberFirmTeam();
    }
    else
    {

        populatepeoplePickerDivMemberFirmTeam();
    }
}

// This function can be removed if you do not need to support IE
async function getTokenRedirect(request, account) {
    request.account = account;
    return await myMSALObj.acquireTokenSilent(request).catch(async (error) => {
        console.log("silent token acquisition fails.");
        if (error instanceof msal.InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            console.log("acquiring token using redirect");
            myMSALObj.acquireTokenRedirect(request);
        } else {
            console.error(error);
        }
    });
}
async function signIn() {

    return myMSALObj.loginRedirect(loginRequest).then(handleResponse).catch(function (error) {
        if (error.errorMessage.includes('Error opening popup window.')) {

            showPopupBlockerMessage();
        }
    });
}
function isValidAction(action) {
    if (action !== 'save' && gblItemsLeftToBeSubmitted > 0) {
        showModalMessage(waitForUploadFileMessage);
        reActivateAutoSave();
        return false;
    }
    if ((action == 'submit' || action == 'reject' || action == 'approve' || action == 'lastReview')) {
        cleanMissingRequiredFields();
        switch (false) {
            case (getMissingRequiredFields().length == 0):
                showModalMessage(requiredFieldsMessage);
                highlightMissingRequiredFields();
                reActivateAutoSave();
                return false;
            case (getInvalidFields() == 0):
                showModalMessage(invalidFieldsMessage);
                reActivateAutoSave();
                return false;
        }
    }
    if (action == 'submit' && !validateRequiredFinalVersions()) {
        $('.attachment-section').find(".span-to-highlight").addClass("bg-missingRequiredField");
        showModalMessage(submittingWithoutRequiredFinalVersions);
        reActivateAutoSave();
        return false;
    }
    if (getInvalidTextFields().length > 0) {
        showModalMessage(textFieldLongerThan255);
        highlightRequiredFields(getInvalidTextFields());
        reActivateAutoSave();
        return false;
    }

    return true;
}

function RequiredFieldsForDiscontinue(securityContext) {
    this.securityContext = securityContext;
    this.peoplePickerS24 = $("#Series24");
    this.requiredFields = ["Series24"];

    this.hasMissingRequiredFields = function () {
        return this.getRequiredInputsForDiscontinue().length > 0;
    }
    this.isSeries24EmptyBeforeDiscontinue = function () {
        return ((this.securityContext.isPendingForIndustryRepresentative() || this.securityContext.isPendingForSubmission()) && !isPeoplePickerPopulated());
    }
    this.isCommentEmpty = function (textarea) {
        return textarea.val().trim() == "";
    }
    this.highlightMissingRequiredFields = function () {
        highlightRequiredFields(this.getRequiredInputsForDiscontinue());
    }
    this.cleanMissingRequiredFields = function () {
        for (field in this.requiredFields) {
            var input = getInputName(field, this.requiredFields);
            var parent = input.closest(".required-div");
            parent.find(".span-to-highlight").removeClass("bg-missingRequiredField");
        }
    }
    this.getRequiredInputsForDiscontinue = function () {
        var missingFields = [];
        if (this.isSeries24EmptyBeforeDiscontinue()) {
            missingFields.push('Series24');
        }
        return missingFields;
    }
}

$('.input-data-integer, .input-data-currency').focus(function (e) {
    var input = $(this);
    var numericValue = input.attr("numeric-value");
    input.val(numericValue);
})

$('.input-data-integer, .input-data-float').focus(function (e) {
    var input = $(this);
    var numericValue = input.attr("numeric-value");
    input.val(numericValue);
})

function showModalReactivateForm() {
    $("#confirm-modal .modal-body")[0].innerHTML = reactivateFormMessage;
}

function setDiscontinueFields() {
    var currentDate = new Date();
    $('[name=CanceledBy]').val(currentUser.userId);
    $('[name=CanceledOn]').val(currentDate);
    $('[name=CanceledOn]').attr("approvalDate", currentDate);
}

function setPutOnHoldFields() {
    var currentDate = new Date();
    $('[name=PutOnHoldBy]').val(currentUser.userId);
    $('[name=PutOnHoldOn]').val(currentDate);
    $('[name=PutOnHoldOn]').attr("approvalDate", currentDate);
}

function isPeoplePickerPopulated () {
    if($('#Series24_chosen  .chosen-choices .search-choice').length == 0) {
        return false;
    }
    else
    return true;
}
function getTokenValue()
{
    return "Bearer "+localStorage.getItem("idToken");
}
function getFormType()
{
    return localStorage.getItem("FormType");
}
function getUserRoles()
{
    var userRoles;
    userRoles = localStorage.getItem("UserRoles")==null? '' :localStorage.getItem("UserRoles");
    return userRoles;
}

$(document).ready(function() {
    var yearArray = [];
    var currentYear = new Date().getFullYear();
    for (var i = currentYear; i >= 2014; i--) {
      yearArray.push(i);
    }
    
    // Create first dropdown
    var select1 = $('<select name="IncomeYear1" id="IncomeYear1" class="input-data-select required"></select>').attr('id', 'ddlIncomeYear1').css({ 'width': '100%', 'height': '28px' });
      $('<option></option>')
          .attr('value', '0')
          .text('Select your option')
          .prop('selected', true)
          .prop('disabled',true)
          .prependTo(select1);

      $.each(yearArray, function (index, year) {
          $('<option></option>')
              .attr('value', year)
              .text(year)
              .appendTo(select1);
      });
      $('#dropdown-container1').append(select1);
    
    // Create second dropdown
      var select2 = $('<select name="IncomeYear2" id="IncomeYear2" class="input-data-select required"></select>').attr('id', 'ddlIncomeYear2').css({ 'width': '100%', 'height': '28px' });
      $('<option></option>')
          .attr('value', '0')
          .text('Select your option')
          .prop('selected', true)
          .prop('disabled',true)
          .prependTo(select2);
      $.each(yearArray, function (index, year) {
          $('<option></option>')
              .attr('value', year)
              .text(year)
              .appendTo(select2);
      });
      $('#dropdown-container2').append(select2);
    
    // Create third dropdown
    var select3 = $('<select name="IncomeYear3" id="IncomeYear3" class="input-data-select required"></select>').attr('id', 'ddlIncomeYear3').css({ 'width': '100%', 'height': '28px' });
      $('<option></option>')
          .attr('value', '0')
          .text('Select your option')
          .prop('selected', true)
          .prop('disabled',true)
          .prependTo(select3);
      $.each(yearArray, function (index, year) {
          $('<option></option>')
              .attr('value', year)
              .text(year)
              .appendTo(select3);
      });
      $('#dropdown-container3').append(select3);

// Initial check on page load
toggleRedAsterisk();
  });
if(localStorage.getItem("CurrentAccessPage")?.toString()!="CrossBorderForm"){
    hideSpinner();    
}

// Function to toggle the visibility of the red asterisk
function toggleRedAsterisk() {
    if ($('#privateType').is(':checked')) {
        $('#ClientAuditStatement > .red-asterik').removeClass('visibility-hidden');
        $('#ClientCapTable > .red-asterik').removeClass('visibility-hidden');
    } else {
        $('#ClientCapTable > .red-asterik').addClass('visibility-hidden');
        $('#ClientAuditStatement > .red-asterik').addClass('visibility-hidden');
    }
}

// Event listener for the radio buttons
$('input[name="ClientTypeXB"]').change(function () {
    toggleRedAsterisk();
});
  function printCrossBorderForm()
{
    //updating default with live value
    document.getElementById("ProjectName").defaultValue = document.querySelector('input[name=ProjectName]').value;
    document.getElementById("ClientName").defaultValue = document.querySelector('input[name=ClientName]').value;
    document.getElementById("IncomeRevenue1").defaultValue = document.querySelector('input[name=IncomeRevenue1]').value;
    document.getElementById("IncomeRevenue2").defaultValue = document.querySelector('input[name=IncomeRevenue2]').value;
    document.getElementById("IncomeRevenue3").defaultValue = document.querySelector('input[name=IncomeRevenue3]').value;
    document.getElementById("IncomeRevenuePercentage1").defaultValue = document.querySelector('input[name=IncomeRevenuePercentage1]').value;
    document.getElementById("IncomeRevenuePercentage2").defaultValue = document.querySelector('input[name=IncomeRevenuePercentage2]').value;
    document.getElementById("IncomeRevenuePercentage3").defaultValue = document.querySelector('input[name=IncomeRevenuePercentage3]').value;
    document.getElementById("OtherProposedTransactionType").defaultValue = document.querySelector('input[name=OtherProposedTransactionType]').value;
    //ClientType : radio button
    var ClientTypeXB = [];
    $('input[name="ClientTypeXB"]:checked').each(function() {
        ClientTypeXB.push($(this).val());
    });
    //CapitalRaiseOption : checkbox
    var CapitalRaiseOption = [];
    $('input[name="CapitalRaiseOption"]:checked').each(function () {
      CapitalRaiseOption.push($(this).val());
    });
    //EnterpriseValue : radio button
    var EnterpriseValue = [];
    $('input[name="EnterpriseValue"]:checked').each(function() {
        EnterpriseValue.push($(this).val());
      }); 
    //Text Area
    var ExpectedCompanyValueComments = $('#ExpectedCompanyValueComments').val();
    //Industry dropdown
    var IndustryselectedValue = $('#Industry').val();
    var CompanySubsectorComments = $('#CompanySubsectorComments').val();
    var ShareholderObjectivesComments = $('#ShareholderObjectivesComments').val();
    var GeographConcentrationComments = $('#GeographConcentrationComments').val();
    var ClientAffiliateComments = $('#ClientAffiliateComments').val();    
    var USCounterpartiesContactComments = $('#USCounterpartiesContactComments').val();
    //Attachments -- Done
    var DCFComments = $('#DCFComments').val();
    var IndustryRepresentativeComments = $('#IndustryRepresentativeComments').val();
    var Series24Comments = $('#Series24Comments').val();
    //DCF Engagement Team -- done
    //DFCRiskRating radio button
    var DFCRiskRating = [];
    $('input[name="DFCRiskRating"]:checked').each(function() {
        DFCRiskRating.push($(this).val());
    });   
    var DFLOW = $('#DFLOW').val();  
    document.getElementById("IndustryRepresentativeSignature").defaultValue = document.querySelector('input[name=IndustryRepresentativeSignature]').value;
    document.getElementById("DCFDateApproval").defaultValue = document.querySelector('input[name=DCFDateApproval]').value;
    $("#DCFDateApproval").disabled = true;
    $("#IndustryRepresentativeSignature").disabled = true;

    var IndustryCommentsAfterApproved = $('#IndustryCommentsAfterApproved').val();
    document.getElementById("Series24Signature").defaultValue = document.querySelector('input[name=Series24Signature]').value;
    document.getElementById("Series24DateApproval").defaultValue = document.querySelector('input[name=Series24DateApproval]').value;
    var Series24CommentsAfterApproved = $('#Series24CommentsAfterApproved').val();
    document.getElementById("CanceledBy").defaultValue = document.querySelector('input[name=CanceledBy]').value;
    document.getElementById("CanceledOn").defaultValue = document.querySelector('input[name=CanceledOn]').value;
    document.getElementById("PutOnHoldBy").defaultValue = document.querySelector('input[name=PutOnHoldBy]').value;
    document.getElementById("PutOnHoldOn").defaultValue = document.querySelector('input[name=PutOnHoldOn]').value;
    var CommentsReviewAssistant = $('#CommentsReviewAssistant').val();
    var contents = $("#updateForm").html();
    for (var i = 1; i <= 16; i++) {
        contents = contents.replace('<div class="col-xs-4 align-center template-icon-'+[i]+' "><a href="/" onclick="return false;"><div class="glyphicon glyphicon-file open-templates"></div></a></div>', '<div class="col-xs-4 align-center template-icon-'+[i]+' "><div class="glyphicon glyphicon-file open-templates"></div></div>');
    }
    for (var i = 1; i <=  $('#required-DocumentTypes').children('div').length + 8; i++) {
        contents = contents.replace('<div class="col-xs-4 align-center template-icon-'+[i]+' "><a href="/" onclick="return false;" class="notification"><div class="glyphicon glyphicon-file open-templates"></div><span class="badge open-templates"><span class="glyphicon glyphicon-bell"></span></span></a></div>', '<div class="col-xs-4 align-center template-icon-'+[i]+' "><a class="notification"><div class="glyphicon glyphicon-file open-templates"></div><span class="badge open-templates"><span class="glyphicon glyphicon-bell"></span></span></a></div>');
    }

    var frame1 = $('<iframe />');
     frame1[0].name = "frame1";
     frame1.css({ "position": "absolute", "top": "-1000000px" });
     $("body").append(frame1);
     var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument.document ? frame1[0].contentDocument.document : frame1[0].contentDocument;
     frameDoc.document.open();

     //Create a new HTML document.
     frameDoc.document.write('<html><head>');
     //Append the external CSS file.
     frameDoc.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">');
     frameDoc.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.css">');
     frameDoc.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/chosen/1.8.7/chosen.min.css">');
     frameDoc.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">');
    //  frameDoc.document.write('<link rel="stylesheet" type="text/css" href="/styles.scss" media="print">');
    frameDoc.document.write('<link rel="stylesheet" type="text/css" href="/styles.css" media="print">');
//  frameDoc.document.write('<style>@media print {.print-page-break {page-break-inside: always;}.pending-icon-color {color: #FF9800;}}</style>');
     frameDoc.document.write('</head><body>');
     //Append the DIV contents.
     frameDoc.document.write(contents);
     frameDoc.document.write('</body></html>');
     frameDoc.document.close();
     frameDoc.document.getElementById('ExpectedCompanyValueComments').value = ExpectedCompanyValueComments;
     frameDoc.document.getElementById('CompanySubsectorComments').value = CompanySubsectorComments;
     frameDoc.document.getElementById('ShareholderObjectivesComments').value = ShareholderObjectivesComments;
     frameDoc.document.getElementById('GeographConcentrationComments').value = GeographConcentrationComments;
     frameDoc.document.getElementById('ClientAffiliateComments').value = ClientAffiliateComments;
     frameDoc.document.getElementById('USCounterpartiesContactComments').value = USCounterpartiesContactComments;
     frameDoc.document.getElementById('DCFComments').value = DCFComments;
     frameDoc.document.getElementById('DFLOW').value = DFLOW;
     frameDoc.document.getElementById('IndustryRepresentativeComments').value = IndustryRepresentativeComments;
     frameDoc.document.getElementById('Series24Comments').value = Series24Comments;
     frameDoc.document.getElementById('IndustryCommentsAfterApproved').value = IndustryCommentsAfterApproved;
     frameDoc.document.getElementById('Series24CommentsAfterApproved').value = Series24CommentsAfterApproved;
     frameDoc.document.getElementById('CommentsReviewAssistant').value = CommentsReviewAssistant;
     
    for (var i = 0; i < CapitalRaiseOption.length; i++) {
      $(frameDoc.document).find('input[type="checkbox"][value="' + CapitalRaiseOption[i] + '"]').prop('checked', true);
    }
    for (var i = 0; i < EnterpriseValue.length; i++) {
        $(frameDoc.document).find('input[type="radio"][value="' + EnterpriseValue[i] + '"]').prop('checked', true);
    }
    for (var i = 0; i < ClientTypeXB.length; i++) {
        $(frameDoc.document).find('input[type="radio"][value="' + ClientTypeXB[i] + '"]').prop('checked', true);
    }
    $(frameDoc.document).find('#Industry').val(IndustryselectedValue);
    for (var i = 0; i < DFCRiskRating.length; i++) {
        $(frameDoc.document).find('input[type="radio"][value="' + DFCRiskRating[i] + '"]').prop('checked', true);
    }       
    setTimeout(function () {
         window.frames["frame1"].focus();
         window.frames["frame1"].print();
         frame1.remove();
      }, 500);
}

//document.addEventListener('DOMContentLoaded', function() {
//    // Get references to the radio buttons
//    const publicTypeRadio = document.getElementById('publicType');
//    const privateTypeRadio = document.getElementById('privateType');
//    const financialStatementsAsterisk = document.getElementById('financialStatementsAsterisk');
  
//    // Function to update the asterisk visibility
//    function updateAsteriskVisibility() {
//      if (privateTypeRadio.checked) {
//        financialStatementsAsterisk.classList.remove('visibility-hidden');
//      } else {
//        financialStatementsAsterisk.classList.add('visibility-hidden');
//      }
//    }
  
//    // Add event listeners to the radio buttons
//    publicTypeRadio.addEventListener('change', updateAsteriskVisibility);
//    privateTypeRadio.addEventListener('change', updateAsteriskVisibility);
  
//    // Initial call to set the correct visibility on page load
//    updateAsteriskVisibility();
//  });

////document.getElementById('uploadDocument').addEventListener('change', handleFileUpload);
// //to show nre fields after october 2024
function checkCreatedDate() {
    const createdDate = new Date(CreatedDate);
    const cutoffDate = new Date('2025-03-12');
    if (createdDate < cutoffDate) {
        $("#selectedClientField").css({ display: "none" }),
        $("#ClientAuditStatement").css({ display: "none" }),
        $("#ClientCapTable").css({ display: "none" }),
        $("#DFLOW").css({ display: "none" })
        displayNewquestions = false;
     }
}
$('#DFLOW').on('input', function () {
    var text = $(this).val();
    processAndStoreUrls(text);
});

function processAndStoreUrls(text) {
    if (!text) {
        $('#dflowLinks').html(''); 
        return;
    }

    // regular expression to find URLs in a block of text
    var urlPattern = /\b(https?:\/\/[^\s]+|ftp:\/\/[^\s]+|file:\/\/[^\s]+)\b/gi;

    
    var urls = text.match(urlPattern);

    // if there are URLs creates clickable links
    if (urls) {
        var linkedText = urls.map(function (url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        }).join('<br>'); // join with line breaks if there are multiple URLs

        $('#dflowLinks').html(linkedText);
    } else {
        $('#dflowLinks').html(''); 
    }
}


